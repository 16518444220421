.display {
  font-weight: 300;
  line-height: 1.2;
  &-1 {
    font-size: 4rem;
  }
  &-2 {
    font-size: 2.75rem;
  }
  &-3 {
    font-size: 2rem;
  }
  &-4 {
    font-size: 1.5rem;
  }
  &-5 {
    font-size: 1.2rem;
  }
  &-6 {
    font-size: 1rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
}

.btn {
  font-family: $font-family-heading;
  text-transform: uppercase;
  &-normal {
    font-family: $font-family-sans-serif;
  }
  &-primary {
    background: $cl-brand-2;
    color: $cl-brand-8;
    &:hover {
      background: lighten($cl-brand-2, 5%);
      color: $cl-brand-8;
    }
  }
  &-tiny {
    padding: 0 0.25rem;
  }
  &-note {
    display: none;
    position: absolute;
    right: 5px;
  }
  &-list {
    .btn + .btn {
      margin-left: 0.5rem;
    }
  }
}
*:hover > .btn-note {
  display: inline-block;
}

.table {
  th {
    font-family: $font-family-heading;
    text-transform: uppercase;
    letter-spacing: 1px;
    .clickable {
      text-decoration: underline;
    }
  }
  td {
    font-family: $font-family-sans-serif;
  }
  th,
  td {
    position: relative;
    padding: 10px 15px;
    font-size: 0.85rem;
  }
  &-middle {
    th,
    td {
      vertical-align: middle;
    }
  }
  &-baseline {
    th,
    td {
      vertical-align: baseline;
    }
  }
  .btn-group .btn + .btn {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.card {
  &-title {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  &-subtitle {
    font-size: 0.85rem;
  }
  &-header {
    position: relative;
    .btn.btn-link {
      font-weight: 700;
      color: $cl-brand-5;
      &:hover,
      &:focus {
        color: $cl-brand-2;
        text-decoration: none;
      }
    }
    .actions {
      position: absolute;
      top: 15px;
      right: 20px;
      .btn-pos {
        border-radius: 0;
        padding: 0.25rem 0.5rem;
      }
    }
  }
  &-text {
    font-family: $font-family-sans-serif;
  }
}

.unavailable {
  text-align: center;
  padding: 40px 0;
}

.slide-top {
  -webkit-animation: slide-top 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.accordion {
  .card .card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    .btn-link {
      color: $cl-brand-2;
      padding-left: 0;
      &.collapsed {
        color: $cl-brand-4;
      }
    }
  }
}

.card-columns .card,
.card-deck .card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.text {
  &-logan {
    color: $cl-brand-3;
  }
  &-secondary-border {
    display: inline-block;
    color: $secondary;
    &:after {
      content: '';
      display: block;
      margin-top: 0.5rem;
      width: 70%;
      border: 2px solid $secondary;
      border-radius: 6px;
      -webkit-transition: width 200ms; /* Safari */
      transition: width 200ms;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
  &-head {
    font-family: $font-family-heading;
  }
  &-help {
    font-family: $font-family-sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 0.9rem;
    color: $secondary;
  }
}

.badge {
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
  &-muted {
    background: #e3e3e3;
    color: $cl-brand-3;
  }
  &-blackcurrant {
    background: $cl-brand-1;
    color: $cl-brand-8;
  }
}

.card-columns {
  column-count: 5;
}
.pagination {
  .page-link {
    span,
    i {
      pointer-events: none;
    }
  }
  .input-group {
    &-text,
    .form-control {
      border-radius: 0;
    }
    .form-control {
      width: 70px;
      text-align: right;
      padding-top: 5px;
    }
  }
}

.list-group-item {
  font-family: $font-family-sans-serif;
  font-size: 1rem;
}
.list-group-sm {
  & > .list-group-item {
    padding: 0.25rem 1rem;
  }
}
.modal {
  .close {
    outline: none;
  }
}

.form-group {
  label {
    font-family: $font-family-heading;
  }
}

.custom-file {
  display: inline;
  position: relative;
  input {
    visibility: hidden;
    position: absolute;
  }
  & + .btn {
    margin-left: 0.75rem;
  }
}

.bg {
  &-gray {
    background: #9a9ab1;
    color: #fff;
  }
  &-primary {
    background: $cl-brand-1;
    color: #fff;
  }
}
.group-title {
  .title-name {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    .inspect-name {
      padding: 5px 10px 5px 10px;
      background: #321640;
      color: #fff;
      border-radius: 5px;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
}
.infobar {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  .info-car {
    h4 {
      font-size: 24px;
      margin-bottom: 5px;
      opacity: 0.8;
      span {
        text-transform: uppercase;
        margin-right: 5px;
      }
      i {
        position: relative;
        top: 3px;

        font-size: 32px;
        margin-right: 5px;
      }
    }
  }
  .image-svg {
    margin: 5px 0 10px 0;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    .group-image {
      position: relative;
      animation: opaa 2s infinite;
      width: 39%;
      img {
        width: 100%;
      }
    }
  }
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    flex-direction: column;
    & > li {
      display: inline-block;
      font-family: $font-family-heading;
      margin-bottom: 3px;
      .group-info {
        display: flex;
      }
      .title-detail {
        font-size: 13px;
        background: #321342;
        padding: 4px 10px;
        border-radius: 5px;
        color: #fff;
        margin-right: 8px;
        text-transform: uppercase;
      }
      span {
        font-size: 15px;
        margin-right: 5px;
        line-height: 27px;
        text-transform: uppercase;
        &.badge {
          padding: 0 10px !important;
        }
      }
    }
  }
}
.modal-full {
  min-width: 95%;
  .group-img {
    display: flex;
    .item-img {
      overflow: hidden;
      width: 50%;
      margin: 10px;
      margin-bottom: 0;
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
.damage-point {
  position: absolute;
  line-height: 1;
  text-align: center;
  margin-left: -11px;
  margin-top: -11px;
  z-index: 101;
  .wait {
    position: absolute;
    width: 325px;
    height: 32px;
    top: -5px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    opacity: 0.9;
  }
  .admin-point {
    position: absolute;
    right: 100%;
    top: 0;
    line-height: 18px;
    background: #e9eaed;
    color: #000;
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }
  .admin-point-right {
    position: absolute;
    left: 100%;
    top: 0;
    line-height: 18px;
    background: #e9eaed;
    color: #000;
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }

  & > i {
    color: #ffed89;
    line-height: 1;
  }

  & > span {
    font-family: $font-family-heading;
    font-size: 0.75rem;
    color: #ffed89;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: middle;
    line-height: 1;
    margin-left: 0;
  }

  .agent-damage {
    position: absolute;
    left: 100%;
    top: 0;
    line-height: 21px;
  }

  .list-group {
    line-height: 1;
    max-height: 180px;
    overflow-y: auto;
    position: absolute;
    top: 20px;
    left: 0;
    border-radius: 0;

    & > li {
      padding: 0;
      text-align: left;

      a {
        white-space: nowrap;
        font-size: 0.75rem;
        font-family: $font-family-heading;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: block;
        padding: 0.5rem;
        cursor: pointer;
      }
    }
  }
}
#Loading {
  position: fixed !important;
  z-index: 900;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85) !important;
}
.ping-priority {
  animation: ping 2s infinite;
}
@keyframes ping {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
