#Workspace {
  font-family: $font-family-heading;

  & > .routes {
    position: relative;
  }

  .InspectionBoard {
    @mixin center {
      left: 0;
      right: 0;
      margin: auto;
    }

    @mixin eye($size) {
      position: absolute;
      background: black;
      margin: auto;
      height: $size;
      width: $size;
      border-radius: 50%;
    }

    @mixin fluff {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-bottom: 80px solid transparent;
    }

    /* colours */
    $bkgd: #f5e3e0;
    $fur: #ee9837;
    $floof: #fff;
    $tongue: #e8b4bc;

    body {
      background: $bkgd;
    }

    .clover {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }

    .corgi {
      @include center;
      position: relative;
      width: 200px;
      height: 300px;
    }

    .head {
      position: absolute;
      z-index: 1;
      top: 60px;
      left: 100px;

      &:after {
        /* cheeks */
        position: absolute;
        top: 80px;
        left: -100px;
        content: '';
        border: 100px solid transparent;
        border-top: 80px solid $fur;
      }

      .forehead {
        position: absolute;
        top: 20px;
        left: -60px;
        height: 100px;
        width: 120px;
        background: $fur;
        border-radius: 40%;
      }
    }

    .snout {
      /* an upside down css heart <3 */
      position: relative;
      z-index: 2;
      top: 60px;
      left: -40px;

      &:before,
      &:after {
        background: $floof;
        position: absolute;
        content: '';
        top: 10px;
        width: 50px;
        height: 80px;
        border-top: 1px solid #ddd;
        border-radius: 60px 60px 0 0;
        -webkit-transform: rotate(225deg);
        -moz-transform: rotate(225deg);
        transform: rotate(225deg);
      }

      &:after {
        left: 30px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }

    .bridge {
      /* white fur between eyes */
      position: absolute;
      top: -40px;
      left: 30px;
      border: 10px solid transparent;
      border-bottom: 40px solid $fur;
      border-bottom-color: $floof;
    }

    .booper {
      /* nose */
      background: black;
      position: absolute;
      z-index: 1;
      top: 35px;
      left: 20px;
      height: 30px;
      width: 40px;
      border-radius: 50px 50px 70px 70px;
    }

    .eyes {
      $size: 13px;
      $posX: 25px;
      position: absolute;
      top: 60px;

      .left {
        @include eye($size);
        left: $posX;
      }

      .right {
        @include eye($size);
        right: $posX;
      }
    }

    .ears {
      /* variables provide symmetry */
      $posX: -40px;
      $top: 35px;
      $rotate: 45deg;
      $skewX: 40deg;
      $scale: (1, 0.866);

      .left {
        background-color: $fur;
        position: absolute;
        left: $posX;
        top: $top;
        transform: rotate($rotate) skewX($skewX) scale($scale);
      }

      .right {
        background-color: $fur;
        position: absolute;
        right: $posX;
        top: $top;
        transform: rotate(-($rotate)) skewX(-($skewX)) scale($scale);
      }

      .right,
      .right:before,
      .right:after,
      .left,
      .left:before,
      .left:after {
        width: 7em;
        height: 6em;
      }

      .right,
      .right:before,
      .right:after {
        border-radius: 20% 20% 40% 20%;
      }

      .left,
      .left:before,
      .left:after {
        border-radius: 20% 20% 20% 40%;
      }
    }

    .tongue {
      background: $tongue;
      position: absolute;
      z-index: 1;
      top: 30px;
      left: 15px;
      width: 30px;
      height: 40px;
      border-radius: 0 0 40% 40%;
      -webkit-animation: happydoggo 0.5s infinite;
      animation: happydoggo 0.5s infinite;
    }

    @keyframes happydoggo {
      0% {
        top: 30px;
      }

      50% {
        top: 25px;
      }

      100% {
        top: 30px;
      }
    }

    @-webkit-keyframes happydoggo {
      0% {
        top: 30px;
      }

      50% {
        top: 25px;
      }

      100% {
        top: 30px;
      }
    }

    .mouth {
      $size: 62px;
      background: $floof;
      position: relative;
      width: $size;
      height: $size;
      top: 100px;
      bottom: 0;
      left: -31px;
      border: 1px solid #ddd;
      border-radius: 50%;
    }

    .magnificent-floof {
      /* see mixin at top */
      $radius: 40%;
      position: absolute;
      top: 146px;
      right: 0;
      bottom: 0;
      left: 0px;
      border-left: 100px solid transparent;
      border-right: 100px solid transparent;
      border-top: 160px solid $floof;
      border-radius: 0 0 0 50px;

      .left {
        @include fluff;
        border-right: 80px solid $floof;
        top: -140px;
        left: -100px;

        &:before {
          @include fluff;
          border-right: 80px solid $floof;
          top: 15px;
          left: -10px;
        }

        &:after {
          @include fluff;
          border-right: 80px solid $floof;
          top: 38px;
          left: -2px;
        }
      }

      .right {
        @include fluff;
        border-left: 80px solid $floof;
        top: -140px;
        right: -100px;

        &:before {
          @include fluff;
          border-left: 80px solid $floof;
          top: 15px;
          right: -10px;
        }

        &:after {
          @include fluff;
          border-left: 80px solid $floof;
          top: 38px;
          right: -2px;
        }
      }
    }

    .Waitting {
      padding: 1rem;

      &.opacity {
        .group-wait {
          .img-wait {
            opacity: 1;
          }

          .group-text {
            opacity: 1;
          }
        }
      }

      .group-wait {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 520px;
        background: #321640;
        border-radius: 10px;
        position: relative;

        .group-text {
          opacity: 0.3;
          transition: 1s;
          position: relative;
          top: -55px;
          left: 10px;

          .loading {
            margin-bottom: 55px;
          }

          h1 {
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 45px;
          }

          button {
            background: #fff;
            color: #321640;
            padding: 10px 25px;
            border-radius: 25px;
          }

          #Spinner_icon {
            & > div {
              position: absolute;
              left: 42%;
              width: 75px;
              height: 75px;

              > div {
                border-color: #fff;
                border-left-color: rgba(50, 22, 64, 0.4);
              }

              & > i {
                font-size: 30px;
                width: 30px;
                height: 30px;
                color: #fff;
              }
            }
          }
        }

        .img-wait {
          position: absolute;
          width: 55%;
          top: 60%;
          opacity: 0.3;
          transition: 1s;
        }

        .layout-top {
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          border-left: 400px solid transparent;
          border-right: 400px solid transparent;
          border-top: 65px solid #e9eaed;
        }
      }
    }

    .start {
      .start-info {
        position: relative;
        top: -5px;
        background-color: #321640;
        width: 100%;
        overflow: hidden;
        height: 100vh;
        .start-inspect {
          height: 310px;
          overflow: hidden;
          position: relative;
          z-index: 5;
          padding: 80px 0 20px 0;

          .box-btn {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            top: -30px;

            .btn-inspect {
              position: relative;

              span {
                display: inline-block;
                color: #fff;
                padding: 10px 28px;
                background: #321640;
                transition: 1s;
                border-radius: 10px;
                text-transform: uppercase;
                font-size: 25px;

                &:hover {
                  cursor: pointer;
                  border: 1px solid #321640;
                  background: #fff;
                  color: #321640;
                }
              }

              .box-1 {
                width: 285px;
                height: 225px;
                top: 100%;
                left: -185%;
                position: absolute;
                background: #fff;
                border-radius: 10px;
                z-index: 999;
                cursor: pointer;

                img {
                  width: 100%;
                }
              }

              .box-2 {
                width: 285px;
                height: 225px;
                top: 100%;
                right: -185%;
                position: absolute;
                background: #fff;
                border-radius: 10px;
                z-index: 999;
                cursor: pointer;

                img {
                  width: 100%;
                }
              }
            }
          }
        }

        .box-hotkey {
          width: 100%;
          text-align: center;
          padding: 10px 20px;
          border-radius: 10px;
          background: #fff;
          opacity: 0.8;

          .title {
            color: #321640;
            text-transform: uppercase;
            font-size: 28px;
          }

          .btn-hotkeys {
            display: flex;

            .hotkey {
              display: flex;
              justify-content: center;
              align-items: center;

              .button {
                background-color: #321640;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 18px;
                text-transform: uppercase;
                cursor: pointer;
              }
            }
          }
        }

        .box-info {
          display: flex;
          border-radius: 10px;
          background: #fff;
          color: #321640;
          opacity: 0.8;
          flex-direction: column;

          .time {
            h2 {
              margin-bottom: 10px;
              font-size: 25px;

              & > span {
                display: inline;
                color: #32164088;
                margin-left: 1rem;
              }
            }

            .ani-car-run {
              margin-bottom: 20px;
            }

            .progress {
              margin-bottom: 20px;
            }

            .number {
              font-size: 38px;
              text-align: center;
            }
          }

          .box-total {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            position: relative;
            border-bottom: 2px solid #321640;

            .title {
              color: #321640;
              text-transform: uppercase;
              font-size: 28px;
            }

            .number {
              font-size: 32px;
            }
          }

          .box-item {
            .box-2,
            .box-3,
            .box-4,
            .box-5 {
              padding: 15px 30px;
              display: inline-block;
              width: 50%;
              text-align: center;

              .title {
                color: #321640;
                font-size: 18px;
                text-transform: none;
              }

              .number {
                font-size: 40px;
                line-height: 42px;
              }
            }
          }
        }

        .info-task {
          position: relative;
          z-index: 3;

          .total,
          .today {
            position: relative;

            #Spinner_icon {
              position: absolute;
              top: 0;
              left: 0;
              height: 100% !important;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 10 !important;
              background-color: #fff;
              opacity: 0.9;

              &:after {
                content: 'Pave Is Loading';
                position: absolute;
                margin-top: 45px;
                text-transform: uppercase;
              }
            }
          }
        }

        .title {
          color: #fff;
          text-transform: uppercase;
        }
      }
    }

    .tire {
      .react-select__menu {
        position: relative;

        .react-select__menu-list {
          .react-select__option {
            font-size: 13px;
          }
        }
      }
    }

    .Actions {
      padding-top: 1rem;
      height: 100vh;
      .subtitle {
        text-align: center;
      }
    }
    .VehiclePhoto {
      position: relative;
      height: 100%;

      img {
        border-radius: 5px;
        width: 100%;
      }

      .rnd-damage-box {
        .rnd-damage {
          border: 2px dashed rgb(255, 237, 137);
          opacity: 0.5;
          width: 100%;
          height: 100%;

          &:hover {
            opacity: 1;
            border: 2px dashed rgb(255, 237, 137);
          }
        }
      }

      .rnd-cage {
        z-index: 100;
        border: 4px dashed red;

        &:hover {
          border: 4px dashed red;
        }
      }

      .imageHolder {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;

        svg {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .list-damage {
        position: absolute;
        left: 0;
        bottom: 0;
        line-height: 1;
        border-radius: 0;
        max-height: 200px;
        overflow-y: auto;

        & > li {
          padding: 0.5rem;
          text-align: left;
          background-color: #fff;
          font-size: 0.8rem;
          opacity: 0.75;

          &:hover {
            opacity: 0.9;
          }
        }
      }

      #Results {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }

      .img-thumb {
        position: relative;
        z-index: 2;
        display: flex;
        height: 100%;

        .tool-image {
          opacity: 0;
        }

        img {
          opacity: 0;
          margin-right: 10px;
        }

        .group-thumb {
          position: absolute;
          left: 45%;
          top: 25%;
          z-index: 999;

          span {
            font-family: $font-family-heading;
            font-size: 18px;
            text-transform: uppercase;
          }

          #Spinner_icon {
            & > div {
              position: absolute;
              left: 40%;
              top: 0;
              width: 90px;
              height: 90px;
              transform: translateX(-25px);

              & > i {
                font-size: 34px;
                width: 34px;
                height: 34px;
              }
            }
          }
        }
      }

      .img-full {
        transition: 0.5s;
        position: relative;
        z-index: 3;
        width: 100%;
        overflow: hidden;

        img {
          cursor: zoom-in !important;
        }

        .layout-front {
          position: absolute;
          top: 0;
          left: 0;
          width: 30%;
          height: 100%;
          background: black;
          opacity: 0.3;
          z-index: 1;
        }

        .layout-rear {
          position: absolute;
          top: 0;
          right: 0;
          width: 30%;
          height: 100%;
          background: black;
          opacity: 0.3;
          z-index: 1;
        }
      }

      .img-full-vin {
        height: calc(100vh - 100px);

        img {
          height: 100%;
          object-fit: contain;
        }

        .App-zoom {
          height: 100%;
        }
      }

      .App-zoom {
        -ms-touch-action: pan-y;
        touch-action: pan-y;
      }

      .tool-image {
        //display: flex;
        .react-select-damage-right {
          left: 100%;
          top: -5px;
        }

        .react-select-damage-left {
          right: 100%;
          top: -5px;
        }

        .react-select-damage-right,
        .react-select-damage-left {
          position: absolute;
          width: 325px;

          .react-select__control {
            min-height: 32px;
            height: 32px;

            .react-select__value-container {
              padding: 0px 8px;
              height: 32px;
              top: -3px;

              .react-select__single-value {
                font-size: 13px;
                top: 65%;

                .react-select__input {
                  height: 21px;
                }
              }
            }

            .react-select__indicators {
              padding: 0;
              position: relative;
              top: -2px;

              .react-select__indicator {
                padding: 0;
              }
            }
          }
        }

        .count-down {
          position: absolute;
          top: 15px;
          right: 25px;
          z-index: 9999;

          &.red {
            .timer {
              color: #c82233;
            }

            .lds-ring div {
              border: 6px solid #c82233;
              border-color: #c82233 transparent transparent transparent;
            }
          }

          .timer {
            position: absolute;
            width: 100%;
            height: 60px;
            left: 1px;
            align-items: center;
            justify-content: center;
            display: flex;
            color: #321640;
            font-size: 20px;
            z-index: 999;
          }

          .lds-ring {
            display: inline-block;
            position: relative;
            width: 60px;
            height: 60px;
          }

          .lds-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 52px;
            height: 52px;
            margin: 4px;
            border: 6px solid #321640;
            border-radius: 50%;
            background-color: #fff;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #321640 transparent transparent transparent;
          }

          .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
          }

          .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
          }

          .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
          }

          @keyframes lds-ring {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        }

        .custom-switch .custom-control-label::after {
          transform: translateX(0);
        }

        .custom-switch
          .custom-control-input:checked
          ~ .custom-control-label::after {
          transform: translateX(0.75rem);
        }

        .custom-control-input:checked ~ .custom-control-label::before {
          opacity: 1;
        }

        .custom-switch .custom-control-label::before {
          opacity: 0.7;
        }
      }
    }

    .inspect-photo {
      position: relative;
      .group-btn {
        display: flex;
        margin-bottom: 10px;

        button {
          width: 50%;

          &:first-child {
            margin-right: 10px;
          }
        }
      }

      .damage-view {
        .component {
          width: 100%;
          display: block;
        }

        .svg-cage {
          overflow: hidden;

          #svg-cage {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              cursor: pointer;
            }

            .show-warning {
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 9999;

              span {
                padding: 3px 5px;
                border-radius: 5px;
                background: #321640;
                color: #fff;
                text-transform: uppercase;
                font-size: 16px;
                text-align: center;
              }
            }
          }

          svg {
            width: 100%;
            height: 100%;
            transition: 1s;

            g {
              path {
                transition: 0.5s;
                opacity: 0.5;
              }
            }

            .svg-hover {
              transition: 0.5s;
              opacity: 1;
            }
          }
        }

        .react-select-damage {
          width: 150px;
          position: relative;
          display: inline-block;

          .react-select__control {
            .react-select__indicators {
              padding: 0;
              position: relative;
              top: -2px;

              .react-select__indicator {
                padding: 0;
              }
            }
          }

          .react-select__menu {
            position: absolute !important;
            top: 35px;
            left: 0;
            width: 200px;
            z-index: 999;

            .react-select__menu-list {
              .react-select__option {
                font-size: 13px;
              }
            }
          }
        }

        li {
          border-radius: 30px;
          margin-bottom: 5px;
          background: rgba(255, 255, 255, 0.6);
          position: relative;

          &.agent-damage {
            overflow: hidden;
          }

          .reject-damage {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #321740;
            top: 0;
            left: 0;
            border-radius: 30px;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;

            padding: 10px 25px;
            transition: 1s;
            transform: translateX(200px);
            opacity: 0;

            &.show {
              opacity: 1;
              z-index: 999;
              transform: translateX(0);
            }
          }

          &:hover {
            opacity: 1;
          }
        }

        .group-button {
          display: flex;

          button {
            width: 50%;
            position: relative;
            margin-left: 5px;
            opacity: 0.5;

            &:first-child {
              margin-right: 5px;
            }

            &.act {
              opacity: 1 !important;

              &::after {
                content: '\2713';
                position: absolute;
                right: 10px;
                font-size: 22px;
                top: 0px;
              }
            }
          }
        }
      }

      .information {
        border-radius: 5px;
        opacity: 0.9;

        .react-select__menu {
          position: relative !important;
        }

        .react-select__control,
        .select {
          border-radius: 25px;
        }

        .react-select__single-value {
          &::before {
            border: 1px solid #000;
          }
        }

        label {
          color: #fff;
          text-transform: uppercase;
          margin-bottom: 10px;
          margin-top: 10px;
          font-weight: bold;
          text-align: center;
          display: block;
        }

        .group-color {
          text-align: center;
          .list-color {
            .item-color {
              display: inline-block;
              .child-item {
                span {
                  color: #000;
                }
                label {
                  height: 45px;
                  border-radius: 15px;
                  text-transform: uppercase;
                  position: relative;
                  border: 2px solid #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &.active-color {
                    &:after {
                      content: '\2713';
                      font-size: 18px;
                      color: #fff;
                      //position: absolute;
                      width: 50%;
                      height: 50%;
                      border-radius: 15px;
                      background: #000;
                    }
                  }

                  &:hover {
                    span {
                      visibility: visible;
                      opacity: 1;
                    }
                  }
                  span {
                    visibility: hidden;
                    width: 150px;
                    color: #000;
                    background-color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    position: absolute;
                    z-index: 999;
                    top: 125%;
                    left: 50%;
                    margin-left: -60px;
                    opacity: 0;
                    -webkit-transition: opacity 1s;
                    transition: opacity 1s;
                    &::after {
                      content: '';
                      position: absolute;
                      bottom: 100%;
                      left: 45%;
                      margin-left: -5px;
                      border: 5px solid transparent;
                      border-bottom-color: #fff;
                    }
                  }
                }
                input {
                  text-align: center;
                  border-radius: 25px;
                }
              }
            }
          }
        }
      }

      .checkAcp {
        display: flex;
        flex-direction: column;

        .false {
          .group-false {
            text-align: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            span {
              display: block;
              text-transform: uppercase;
              font-size: 24px;
              margin-bottom: 15px;
            }

            img {
              width: 60%;
            }
          }
        }
        .waitting {
          .modal-content {
            .modal-body {
              text-align: center;
              padding: 85px 0;
              background: #e9e9ed;

              span {
                font-size: 27px;
                text-transform: uppercase;
              }
            }
          }
        }

        .modal-content {
          button {
            width: auto;
            margin-bottom: 0;
          }
        }

        button {
          width: 100%;
          margin-bottom: 10px;

          i {
            margin-right: 5px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @keyframes opaa {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 0.2;
    }

    50% {
      opacity: 1;
    }

    75% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }
}

.display-duration {
  & > div {
    display: inline-block;
    text-align: center;
    padding: 0.5rem;

    & > span {
      display: block;
      font-size: 2.5rem;
      text-transform: uppercase;
      color: #321342ff;
      line-height: 1;

      & + span {
        font-size: 0.8rem;
        color: #32134266;
      }
    }
  }
}

button.btn-ai {
  outline: none;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: calc(1.5em + 0.75rem + 1px);
  border-radius: 6px;
  letter-spacing: 1px;
  transition: all 0.5s ease-in-out;

  span {
    padding: 0 10px;
  }
}

.wrapp-vin-ai {
  position: relative;
  width: 100%;
  height: 40px;

  .run-vin {
    color: #fff;
    pointer-events: none;
    background: transparent;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    padding: 10px 0 0 0;
    animation: floatText 1s ease;

    .label-vin {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-right: 10px;
    }

    .content-vin {
      letter-spacing: 1px;
    }
  }
}

@keyframes floatText {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}
