$cl-brand-1: #321640;
$cl-brand-2: #49496a;
$cl-brand-3: #9a9ab1;
$cl-brand-4: #767678;
$cl-brand-5: #eceae2;
$cl-brand-6: #dbdbdf;
$cl-brand-7: #e9e9ed;
$cl-brand-8: #f3f3f8;
$font-family-sans-serif: "TD Graphik Web", -apple-system, BlinkMacSystemFont,
	"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
	"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-heading: "Geogrotesque";
$dark: $cl-brand-1;
$primary: $cl-brand-2;
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&display=swap');
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./_sass/general";
@import "./_sass/customize";
@import "./_sass/work";
@import "./_sass/cageProcess";
@import "./_sass/auth";
@import "./_sass/instruction";
@import "./_sass/label";
@import "./_sass/navbar";
@import "./_sass/animations";
@import "./_sass/SpinnerIcon";
