#Navbar {
  &.display-none {
    display: none;
  }
  .navbar {
    border-bottom: 2px solid $white;
    padding: 0 1rem;
    .nav-link {
      font-family: $font-family-heading;
      text-transform: uppercase;
      text-align: center;
      padding: 0.5rem 1.25rem;
      i,
      span {
        opacity: 0.85;
      }
      span {
        display: block;
        font-size: 0.85rem;
        letter-spacing: 1px;
        margin: 0.5rem 0 0;
      }
      &:hover,
      &.active {
        background: rgba(255, 255, 255, 0.1);
        i,
        span {
          opacity: 1;
        }
      }
    }
  }
}
