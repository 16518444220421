.instruction-inspect {
	.Start {
		background-color: #e9eaed !important;
		padding-bottom: 0 !important;
		top: 0 !important;
	}
}
#intruction {
	position: relative;
	.box {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		margin: 50px 0;
		padding: 125px 0;
		overflow: hidden;
		&:hover {
			cursor: pointer;
			.box-move {
				transform: translateY(0);
			}
			.group {
				background: #fff;
				color: #321640;
				animation: box-up-down 5s infinite;
			}
		}
		.box-move {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: #321640;
			transition: 1.5s;
			transform: translateY(-500px);
		}
		.group {
			position: relative;
			z-index: 2;
			text-transform: uppercase;
			background: #321640;
			color: #fff;
			border-radius: 50%;
			width: 180px;
			height: 180px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			transition: 2s;
			i {
				display: block;
				font-size: 55px;
			}
			span {
				display: block;
				margin: 0;
				font-size: 25px;
			}
		}
	}
}
@keyframes box-up-down {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-50px);
	}
	50% {
		transform: translateY(0px);
	}
	75% {
		transform: translateY(-50px);
	}
	100% {
		transform: translateY(0);
	}
}

.InspectionBoard {
	&.instruction-inspect {
		.Start {
			.group-left {
				.select-task {
					display: flex;
					margin-bottom: 35px;
					justify-content: flex-end;
					.group {
						display: flex;
						width: 320px;
						position: relative;
						padding: 10px 30px;
						background: #321640;
						transition: 1s;

						span {
							font-size: 20px;
							display: block;
							margin-bottom: 0;
							width: 70%;
							text-align: start;
							color: #fff;
							line-height: 30px;
						}
						i {
							font-size: 20px;
							width: 30%;
							color: #fff;
							line-height: 30px;
						}
						.layout {
							position: absolute;
							width: 0;
							height: 0;
							left: 49%;
							top: 50px;
							border-left: 15px solid transparent;
							border-right: 15px solid transparent;
							border-bottom: 10px solid #321640;
							opacity: 0;
							transition: 1s;
						}
						.dropdown-task {
							position: absolute;
							width: 100%;
							height: 0px;
							top: 110%;
							left: 0;
							overflow-y: scroll;
							text-align: left;
							background: #321640;
							transition: 1s;

							ul {
								list-style: none;
								padding: 0;
								margin: 0;
							}
							li {
								padding: 15px 30px;
								font-size: 20px;
								color: #fff;
								border-bottom: 1px solid #fff;
								&:hover {
									cursor: pointer;
								}
							}
						}
						&:hover {
							.dropdown-task {
								height: 350px;
							}
							.layout {
								opacity: 1;
							}
						}
					}
				}
				transition: 1s;
				transform: translateX(-200px);
				&.left {
					transform: translateX(0);
				}
				text-align: right;
				padding-top: 50px;
				padding-left: 120px;
				h1 {
					text-transform: uppercase;
					margin-bottom: 35px;
					color: #321640;
					font-size: 48px;
				}
				span {
					display: block;
					margin-bottom: 35px;
					color: #321640;
					font-size: 18px;
					opacity: 0.8;
				}
				button {
					border-radius: 25px;
					color: #fff;
					background: #321640;
					padding: 10px 25px;
					transition: 1s;
					margin-bottom: 35px;
					&:hover {
						background: #fff;
						color: #321640;
					}
				}
			}
			.group-right {
				transition: 1s;
				position: relative;
				img {
					width: 100%;
				}
				&.right {
					.carInspect {
						transform: translateX(0);
					}
				}
				.layout {
					position: absolute;
					border-top: 750px solid #e9eaed;
					border-right: 550px solid transparent;
					z-index: 1;
				}
				.carInspect {
					transform: translateX(200px);
					position: absolute;
					width: 100%;
					top: 7%;
					left: -2%;
					z-index: 2;
					transition: 1s;
					cursor: pointer;
				}
			}
		}
	}
}
