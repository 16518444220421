body {
  background: $cl-brand-7;
}

a {
  color: darken($cl-brand-1, 5%);
  &:hover,
  &:active {
    color: darken($cl-brand-1, 10%);
    text-decoration: none;
  }
}

i + span,
span + i {
  margin-left: 0.5rem;
}

.clickable {
  cursor: pointer;
}
.check-success {
  .modal-content {
    .modal-body {
      .success-image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        .star {
          position: absolute;
          width: 30%;
          top: 20px;
        }
        img {
          width: 40%;
        }
      }
      .success-text {
        text-align: center;
        margin-bottom: 15px;
        p {
          text-transform: uppercase;
          font-size: 25px;
          color: #e67e22;
        }
        span {
          font-size: 20px;
          display: block;
          margin-bottom: 5px;
        }
      }

      .group-button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        button {
          margin-bottom: 5px;
          border-radius: 20px;
          padding: 10px 70px;
        }
      }
    }
  }
}
.testing {
  display: block;
  padding: 18px 30px !important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  animation: pulse 1s infinite;
}
.ribbon {
  position: absolute;
  left: 20px;
  top: -6px;
  z-index: 998;
  background-color: #321640;
  width: 160px;
  border-top-right-radius: 10px;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-top: 10px solid #321640;
  }
  span {
    position: relative;
    display: block;
    text-align: center;
    background: #321640;
    font-size: 18px;
    line-height: 1;
    padding: 12px 8px 10px;
    border-top-right-radius: 8px;
    width: 160px;
    z-index: 999;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    &::before {
      position: absolute;
      content: '';
      height: 6px;
      width: 6px;
      left: -6px;
      top: 0;
      background: #321640;
    }
    &::after {
      position: absolute;
      content: '';
      height: 6px;
      width: 8px;
      left: -8px;
      top: 0;
      border-radius: 8px 8px 0 0;
      background: #321640;
    }
  }
}
.timeout {
  z-index: 9999999 !important;
  .group-timeout {
    display: flex;
    justify-content: center;
    align-items: center;
    .group-text {
      position: absolute;
      padding: 15px 20px;
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 10px;
      .layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0.7;
        z-index: 0;
        border-radius: 10px;
      }
      h3 {
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
        text-transform: uppercase;
        color: #321640;
      }
      span {
        display: block;
        width: 300px;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
      }
      button {
        position: relative;
        z-index: 1;
        padding: 10px 35px;
        border-radius: 25px;
        margin-bottom: 15px;
        background: #321640;
        color: #fff;
      }
    }
    img {
      width: 100%;
    }
  }
}
.hidden {
  transition: 0.5s;
  z-index: 1 !important;
  display: none !important;
  -webkit-animation: fade-out 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.modal-custom {
  top: 25%;
}
.modal-content {
  top: 45px;
}
.modal-body {
  overflow: hidden;
}
.modal-show,
.show-modal {
  display: block;
  -webkit-animation: fade-in 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-in 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.show-modal-pub {
  display: block;
  //transform-origin: 50% 0%;
  animation: pulse 2s;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes swing {
  0% {
    transform: rotate(-15deg);
  }

  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
}
.box {
  padding: 1.5rem;
  &.white {
    background: $white;
  }
  & + .box {
    margin-top: 1.5rem;
  }
}

.subtitle {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 0.25rem 0;
}

.img-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.chat-transcript {
  max-height: 24rem;
  overflow-y: auto;
}

.message-item {
  position: relative;
  .alert {
    padding-right: 12rem;
  }
  .actions {
    border-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
    .btn:hover {
      text-decoration: none;
    }
  }
}
.not-allowed {
  pointer-events: none;
  opacity: 0.3;
  &:hover {
    cursor: not-allowed;
  }
}
.message-form {
  border: 2px dashed $light;
  padding: 10px 15px;
}

.list-group-sm {
  & > .list-group-item {
    padding: 0.25rem 1rem;
    font-size: 85%;
  }
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.Agents {
  .react-datepicker {
    display: flex;
  }
}
#statistic {
  .react-datepicker {
    display: flex;
  }
}
.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-out {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}
@keyframes fade-out {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}
#Outline {
  display: none;
}
