#Alert {
  position: fixed;
  z-index: 899;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0.5rem 0;
  .alert {
    position: relative;
    display: inline-block;
    padding: 10px 45px 10px 15px;
    font-size: 0.9rem;
    background: #fff;
    .btn-close {
      position: absolute;
      top: 50%;
      right: 10px;
      padding: 0;
      margin-top: -13px;
    }
    &-errors {
      border: 2px solid #ed4f32;
    }
    &-done {
      border: 2px solid #15cd72;
    }
    &-notify {
      border: 2px solid #fad01a;
    }
  }
}
.login {
  width: 100%;
  background-color: #321640;
  height: 100vh;
  position: relative;
  form {
    display: none;
    width: 500px;
    padding: 35px 25px;
    background: #fff;
    border-radius: 15px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
    position: relative;
    border: 1px solid #eee;
    .logo {
      margin-bottom: 15px;
    }
  }

  h2 {
    font-size: 20px;
    color: #321640;
    margin-bottom: 20px;
  }
  .btn {
    background: #321640;
    padding: 10px 45px;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
    color: #fff;
    border-radius: 20px;
    &:hover {
      background-color: #fff;
      border: 1px solid #321640;
      color: #321640;
    }
  }
  .form-group {
    position: relative;
    font-size: 17px;
    color: #666;
    width: 100%;
    & + & {
      margin-top: 30px;
    }

    .form-label {
      position: absolute;
      z-index: 10;
      left: 0;
      top: -5px;
      transition: 0.3s;
    }

    .form-control {
      width: 100%;
      position: relative;
      z-index: 3;
      height: 50px;
      background: none;
      border: none;
      padding: 5px 10px;
      transition: 0.3s;
      border-bottom: 1px solid #777;
      color: #555;
      &:invalid {
        outline: none;
      }

      &:focus,
      &:valid {
        outline: none;
        border-color: #321640;
        + .form-label {
          font-size: 15px;
          color: #321640;
          transform: translateY(-15px);
        }
      }
    }
  }

  .on {
    display: relative;
    opacity: 1;
    transform: scale(1);
  }
  .wrong-entry {
    opacity: 0;
    transform: scale(0);
    display: none;
    color: #f00;
    font-size: 16px;
    font-weight: bold;
  }
  #svg-login-u-lamp_tr {
    animation: svg-login-u-lamp_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes svg-login-u-lamp_tr__tr {
    0% {
      transform: translate(199.820761px, -53.247px) rotate(-19.999985deg);
    }
    25% {
      transform: translate(199.820761px, -53.247px) rotate(0deg);
    }
    50% {
      transform: translate(199.820761px, -53.247px) rotate(-20deg);
    }
    75% {
      transform: translate(199.820761px, -53.247px) rotate(-40deg);
    }
    100% {
      transform: translate(199.820761px, -53.247px) rotate(-20deg);
    }
  }
  #svg-login-u-shoe_to {
    animation: svg-login-u-shoe_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes svg-login-u-shoe_to__to {
    0% {
      transform: translate(239.050821px, 436.17388px);
    }
    25% {
      transform: translate(239.050821px, 436.17388px);
    }
    51.25% {
      transform: translate(236px, 436.034776px);
    }
    100% {
      transform: translate(239.05px, 436.17388px);
    }
  }
  #svg-login-u-shoe_tr {
    animation: svg-login-u-shoe_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes svg-login-u-shoe_tr__tr {
    0% {
      transform: rotate(-4.171506deg);
    }
    12.5% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-5deg);
    }
    62.5% {
      transform: rotate(20deg);
    }
    68.75% {
      transform: rotate(-4deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-4.17deg);
    }
  }
  #svg-login-u-exclusion-5_ts {
    animation: svg-login-u-exclusion-5_ts__ts 8000ms linear infinite normal
      forwards;
  }
  @keyframes svg-login-u-exclusion-5_ts__ts {
    0% {
      transform: translate(288.973px, 210.200731px) scale(0.3, 0.3);
    }
    31.25% {
      transform: translate(288.973px, 210.200731px) scale(1, 1);
    }
    75% {
      transform: translate(288.973px, 210.200731px) scale(1, 1);
    }
    100% {
      transform: translate(288.973px, 210.200731px) scale(0.3, 0.3);
    }
  }
  #svg-login-u-group-103__tr {
    animation: svg-login-u-group-103__tr__tr 8000ms linear infinite normal
      forwards;
  }
  @keyframes svg-login-u-group-103__tr__tr {
    0% {
      transform: translate(339.686012px, 442.814006px) rotate(-172deg);
    }
    12.5% {
      transform: translate(339.686012px, 442.814006px) rotate(-176deg);
    }
    25% {
      transform: translate(339.686012px, 442.814006px) rotate(-170deg);
    }
    62.5% {
      transform: translate(339.686012px, 442.814006px) rotate(-177deg);
    }
    68.75% {
      transform: translate(339.686012px, 442.814006px) rotate(-9deg);
    }
    75% {
      transform: translate(339.686012px, 442.814006px) rotate(-3deg);
    }
    81.25% {
      transform: translate(339.686012px, 442.814006px) rotate(-13deg);
    }
    87.5% {
      transform: translate(339.686012px, 442.814006px) rotate(-10deg);
    }
    100% {
      transform: translate(339.686012px, 442.814006px) rotate(-176deg);
    }
  }
  #svg-login-u-group-218__tr {
    animation: svg-login-u-group-218__tr__tr 8000ms linear infinite normal
      forwards;
  }
  @keyframes svg-login-u-group-218__tr__tr {
    0% {
      transform: translate(374.088839px, 399.869107px) rotate(-11.71874deg);
    }
    12.5% {
      transform: translate(374.088839px, 399.869107px) rotate(11deg);
    }
    25% {
      transform: translate(374.088839px, 399.869107px) rotate(-11deg);
    }
    37.5% {
      transform: translate(374.088839px, 399.869107px) rotate(11deg);
    }
    50% {
      transform: translate(374.088839px, 399.869107px) rotate(-12deg);
    }
    56.25% {
      transform: translate(374.088839px, 399.869107px) rotate(10deg);
    }
    68.75% {
      transform: translate(374.088839px, 399.869107px) rotate(-14deg);
    }
    75% {
      transform: translate(374.088839px, 399.869107px) rotate(-20deg);
    }
    87.5% {
      transform: translate(374.088839px, 399.869107px) rotate(-15deg);
    }
    100% {
      transform: translate(374.088839px, 399.869107px) rotate(-11deg);
    }
  }
  #svg-login-s-g4_ts {
    animation: svg-login-s-g4_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes svg-login-s-g4_ts__ts {
    0% {
      transform: translate(373.994995px, 387.46701px) scale(1, 1);
    }
    25% {
      transform: translate(373.994995px, 387.46701px) scale(1.05, 1);
    }
    43.75% {
      transform: translate(373.994995px, 387.46701px) scale(1.25, 1.3);
    }
    62.5% {
      transform: translate(373.994995px, 387.46701px) scale(1, 1);
    }
    68.75% {
      transform: translate(373.994995px, 387.46701px) scale(1.4, 0.3);
    }
    87.5% {
      transform: translate(373.994995px, 387.46701px) scale(1.16, 0.8);
    }
    100% {
      transform: translate(373.994995px, 387.46701px) scale(1, 1);
    }
  }
  #svg-login-u-path-76__ts {
    animation: svg-login-u-path-76__ts__ts 8000ms linear infinite normal
      forwards;
  }
  @keyframes svg-login-u-path-76__ts__ts {
    0% {
      transform: translate(374.142553px, 392.123757px) scale(1, 1);
    }
    62.5% {
      transform: translate(374.142553px, 392.123757px) scale(0.5, 1);
    }
    87.5% {
      transform: translate(374.142553px, 392.123757px) scale(0.5, 2);
    }
    100% {
      transform: translate(374.142553px, 392.123757px) scale(1, 1);
    }
  }
  #svg-login-s-g5_to {
    animation: svg-login-s-g5_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes svg-login-s-g5_to__to {
    0% {
      transform: translate(366.620499px, 166.246422px);
    }
    25% {
      transform: translate(366.620499px, 168px);
    }
    50% {
      transform: translate(366.620499px, 166px);
    }
    75% {
      transform: translate(366.620499px, 164px);
    }
    100% {
      transform: translate(366.620499px, 166px);
    }
  }
  #svg-login-s-g6_to {
    animation: svg-login-s-g6_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes svg-login-s-g6_to__to {
    0% {
      transform: translate(327.397492px, 147.854504px);
    }
    25% {
      transform: translate(327.397492px, 146px);
    }
    50% {
      transform: translate(327.397492px, 145px);
    }
    75% {
      transform: translate(327.397492px, 148px);
    }
    100% {
      transform: translate(327.397492px, 146px);
    }
  }
  #svg-login-u-group-173__to {
    animation: svg-login-u-group-173__to__to 8000ms linear infinite normal
      forwards;
  }
  @keyframes svg-login-u-group-173__to__to {
    0% {
      transform: translate(297.444138px, 130.707882px);
    }
    25% {
      transform: translate(297.444138px, 133px);
    }
    50% {
      transform: translate(297.444138px, 130px);
    }
    75% {
      transform: translate(297.444138px, 127px);
    }
    100% {
      transform: translate(297.444138px, 130.707882px);
    }
  }
  #svg-login-u-group-196__tr {
    animation: svg-login-u-group-196__tr__tr 8000ms linear infinite normal
      forwards;
  }
  @keyframes svg-login-u-group-196__tr__tr {
    0% {
      transform: translate(156.138554px, 225.486799px) rotate(0deg);
    }
    25% {
      transform: translate(156.138554px, 225.486799px) rotate(-24deg);
    }
    50% {
      transform: translate(156.138554px, 225.486799px) rotate(0deg);
    }
    75% {
      transform: translate(156.138554px, 225.486799px) rotate(25deg);
    }
    100% {
      transform: translate(156.138554px, 225.486799px) rotate(0deg);
    }
  }
  #svg-login-u-face_tr {
    animation: svg-login-u-face_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes svg-login-u-face_tr__tr {
    0% {
      transform: translate(92.157029px, 173.148417px) rotate(0deg);
    }
    25% {
      transform: translate(92.157029px, 173.148417px) rotate(20deg);
    }
    50% {
      transform: translate(92.157029px, 173.148417px) rotate(0deg);
    }
    75% {
      transform: translate(92.157029px, 173.148417px) rotate(-20deg);
    }
    100% {
      transform: translate(92.157029px, 173.148417px) rotate(0deg);
    }
  }
  #svg-login-u-group-220__ts {
    animation: svg-login-u-group-220__ts__ts 8000ms linear infinite normal
      forwards;
  }
  @keyframes svg-login-u-group-220__ts__ts {
    0% {
      transform: translate(112.809059px, 152.146477px) scale(1, 1);
    }
    30% {
      transform: translate(112.809059px, 152.146477px) scale(0.8, 1);
    }
    75% {
      transform: translate(112.809059px, 152.146477px) scale(1.2, 1);
    }
    100% {
      transform: translate(112.809059px, 152.146477px) scale(1, 1);
    }
  }
  #svg-login-u-wind {
    animation: svg-login-u-wind_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes svg-login-u-wind_c_o {
    0% {
      opacity: 0.5;
    }
    25% {
      opacity: 0;
    }
    83.75% {
      opacity: 0;
    }
    87.5% {
      opacity: 0.42;
    }
    100% {
      opacity: 0.42;
    }
  }
}
